import React, { Component } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import { Button, Row, Col, Card, Container, Breadcrumb } from "react-bootstrap";
import DataTables from "../../admin/datatable/DataTables";
import SupplierFormModal from "./SupplierFormModal";
import Check from "../../admin/other/Check";
import api from "../../../helper/axiosInstance";

class SupplierIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deps: [],
      modalSuppliershow: false,
      alertShow: false,
      suppliers: [],
      supplier: {
        id: "",
        supplier_name: "",
        supplier_phone: "",
        supplier_address: "",
        supplier_author: "",
        supplier_shipper: "",
      },
      selectedRows: [],

      checkload: true,
    };
  }

  componentDidMount() {
    document.title = "Nhà cung cấp";
    this.getsuppliers();
  }

  getsuppliers = () => {
    api
      .get(`/supplier-show`)
      .then((res) => {
        if (res.data.errCode === 0) {
          this.setState({ checkload: false });
          this.setState({ suppliers: res.data.suppliers });
        }
      })
      .catch((err) => {});
  };

  editSupplier = (item) => {
    this.setState({
      supplier: {
        id: item.id,
        supplier_name: item.supplier_name,
        supplier_phone: item.supplier_phone,
        supplier_address: item.supplier_address,
        supplier_author: item.supplier_author,
        supplier_shipper: item.supplier_shipper,
      },
    });
    this.setState({ modalSuppliershow: true });
  };

  handleDelete = (item) => {
    swal({
      title: "Bạn muốn xóa nhà cung cấp?",
      text: `"${item.supplier_name}"`,
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        api.delete(`/supplier-destroy?id=${item.id}`).then((res) => {
          if (res.data.errCode === 0) {
            toast(res.data.message, { type: "success", autoClose: 1000 });
            this.getsuppliers();
          } else {
            toast(res.data.message, { type: "error", autoClose: 1000 });
          }
        });
      }
    });
  };

  dataTable() {
    const columns = [
      {
        name: "Tên nhà cung cấp",
        selector: (row) => row.supplier_name,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.supplier_name}
          </div>
        ),
        style: {
          div: {
            width: "350px !important",
          },
        },
      },
      {
        name: "Đại diện",
        selector: (row) => row.supplier_author,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.supplier_author}
          </div>
        ),
      },
      {
        name: "Số điện thoại",
        selector: (row) => row.supplier_phone,
        sortable: true,
      },
      {
        name: "Người giao hàng",
        selector: (row) => row.supplier_shipper,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.supplier_shipper}
          </div>
        ),
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.suppliers.length > 0) {
      this.state.suppliers.map((item, i) => {
        data.push({
          id: item.id,
          supplier_name: item.supplier_name,
          supplier_phone: item.supplier_phone,
          supplier_address: item.supplier_address,
          supplier_author: item.supplier_author,
          supplier_shipper: item.supplier_shipper,
          setting: (
            <>
              {new Check().permission(["25"]) ? (
                <Button
                  size="sm"
                  variant="warning me-2"
                  type="button"
                  onClick={() => this.editSupplier(item)}
                  title="Chi tiết nhà cung cấp"
                >
                  <i className="fa-solid fa-pencil"></i>
                </Button>
              ) : null}

              {new Check().permission(["26"]) ? (
                <Button
                  size="sm"
                  variant="danger"
                  type="button"
                  onClick={() => this.handleDelete(item)}
                  title="Xóa nhà cung cấp"
                >
                  <i className="fas fa-trash"></i>
                </Button>
              ) : null}
            </>
          ),
        });

        return item;
      });
    }

    return (
      <DataTables data={data} columns={columns} selectedRows={selectedRows} />
    );
  }

  refreshSupplier = () => {
    this.setState({
      supplier: {
        id: "",
        supplier_name: "",
        supplier_phone: "",
        supplier_address: "",
        supplier_author: "",
        supplier_shipper: "",
      },
    });
  };
  loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  renderRoleList() {
    const modalClose = () => {
      this.setState({ modalSuppliershow: false });
      this.refreshSupplier();
    };

    const modalSubmit = () => {
      this.setState({ modalSuppliershow: false });
      this.refreshSupplier();
      this.getsuppliers();
    };

    return (
      <>
        <Card.Header>
          {new Check().permission(["24"]) ? (
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              onClick={() => this.setState({ modalSuppliershow: true })}
            >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button>
          ) : null}

          <Card.Title>
            <i className="fas fa-list me-1"></i> Danh sách nhà cung cấp
          </Card.Title>
        </Card.Header>

        <Card.Body>
          {this.state.checkload ? this.loading() : this.dataTable()}
        </Card.Body>

        {this.state.modalSuppliershow ? (
          <SupplierFormModal
            show={this.state.modalSuppliershow}
            onHide={modalClose}
            modal={
              this.state.supplier.id === ""
                ? {
                    title: "Thêm nhà cung cấp",
                    button: (
                      <>
                        <i className="fa-solid fa-check" /> Lưu
                      </>
                    ),
                  }
                : {
                    title: "Chi tiết nhà cung cấp",
                    button: (
                      <>
                        <i className="fa-solid fa-pencil" /> Cập nhật
                      </>
                    ),
                  }
            }
            data={this.state.supplier}
            submit={modalSubmit}
          />
        ) : null}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Kho hàng</Breadcrumb.Item>
            <Breadcrumb.Item active>Nhà cung cấp</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col md={12}>
              <Card className="mb-4">{this.renderRoleList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default SupplierIndex;
