import React from "react";
import { Table } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { dateFormatter } from "../../../utils/helpers";

const MarketBillAllByGroupExport = ({
  quantitative,
  selectedClassGroup,
  optionGroup,
}) => {
  if (!quantitative) {
    return "";
  }
  const findMoreMeal = (id_group) => {
    const classGroupItem = quantitative?.costPerDays?.filter(
      (item) => item.id_group == id_group
    )[0];
    if (classGroupItem?.meal_quantity) {
      return classGroupItem.meal_quantity;
    } else {
      return 0;
    }
  };
  let ingredienthandle = [];
  let ingredientMap = new Map();

  quantitative.times?.forEach((time) => {
    time?.foods?.forEach((food) => {
      food?.groups
        ?.filter((group) =>
          optionGroup?.map((option) => option.id)?.includes(group.id)
        )
        ?.forEach((group) => {
          group?.ingredents?.forEach((ingredent) => {
            let key = ingredent.id;
            let quantity = parseFloat(ingredent.ing_quantity);
            if (group.more_meal == null) {
              quantity *= parseFloat(findMoreMeal(group.id));
            } else {
              quantity *= group.more_meal;
            }
            if (ingredientMap.has(key)) {
              let existingIngredent = ingredientMap.get(key);
              existingIngredent.ing_quantity_sum += quantity;
            } else {
              ingredientMap.set(key, {
                ...ingredent,
                ing_quantity_sum: quantity,
              });
            }
          });
        });
    });
  });

  ingredienthandle = Array.from(ingredientMap.values())?.map((item) => {
    let gramMarket =
      (item.ing_quantity_sum * item.volume_market) / item.volume_cook;

    let marketQuantity = Number(gramMarket / item.volume_market).toFixed(2);

    let ingredientPrice = marketQuantity * item.ing_price_expect;
    let gram100Price =
      Math.round(Number((ingredientPrice * 100) / gramMarket)) || 0;

    return {
      ...item,
      ing_quantity_sum: gramMarket,
      marketQuantity: marketQuantity,
      ingredientPrice: ingredientPrice,
      gram100Price: gram100Price,
    };
  });

  let ingredientMapDuTru = new Map();
  let ingredienthandleDuTtru = [];
  quantitative.times?.forEach((time) => {
    time?.foods?.forEach((food) => {
      food?.groups.forEach((group) => {
        if (group.more_meal != null) {
          group?.ingredents?.forEach((ingredent) => {
            let key = ingredent.id;
            let quantity = parseFloat(ingredent.ing_quantity);

            quantity *= group.more_meal;

            if (ingredientMapDuTru.has(key)) {
              let existingIngredent = ingredientMapDuTru.get(key);
              existingIngredent.ing_quantity_sum += quantity;
            } else {
              ingredientMapDuTru.set(key, {
                ...ingredent,
                ing_quantity_sum: quantity,
              });
            }
          });
        }
      });
    });
  });

  ingredienthandleDuTtru = Array.from(ingredientMapDuTru.values())?.map(
    (item) => {
      let gramMarket =
        (item.ing_quantity_sum * item.volume_market) / item.volume_cook;
      let marketQuantity = Number(gramMarket / item.volume_market).toFixed(2);
      let ingredientPrice = marketQuantity * item.ing_price_expect;
      let gram100Price =
        Math.round(Number((ingredientPrice * 100) / gramMarket)) || 0;

      return {
        ...item,
        ing_quantity_sum: gramMarket,
        marketQuantity: marketQuantity,
        ingredientPrice: ingredientPrice,
        gram100Price: gram100Price,
      };
    }
  );

  const findMoney = (id_group, count_group) => {
    if (!count_group) {
      return 0;
    }
    let ingredienthandleByIdGroup = [];

    let ingredientMapByIdGroup = new Map();
    quantitative.times?.forEach((time) => {
      time?.foods?.forEach((food) => {
        food?.groups
          ?.filter((e) => e.id == id_group)
          ?.forEach((group) => {
            group?.ingredents?.forEach((ingredent) => {
              let key = ingredent.id;
              let quantity = parseFloat(ingredent.ing_quantity);
              if (group.more_meal == null) {
                quantity *= parseFloat(findMoreMeal(group.id));
              } else {
                quantity = 0;
              }
              if (ingredientMapByIdGroup.has(key)) {
                let existingIngredent = ingredientMapByIdGroup.get(key);
                existingIngredent.ing_quantity_sum += quantity;
              } else {
                ingredientMapByIdGroup.set(key, {
                  ...ingredent,
                  ing_quantity_sum: quantity,
                });
              }
            });
          });
      });
    });

    ingredienthandleByIdGroup = Array.from(
      ingredientMapByIdGroup.values()
    )?.map((item) => {
      let gramMarket =
        (item.ing_quantity_sum * item.volume_market) / item.volume_cook;
      let marketQuantity = Number(gramMarket / item.volume_market).toFixed(2);

      let ingredientPrice = marketQuantity * item.ing_price_expect;

      let gram100Price =
        Math.round(Number((ingredientPrice * 100) / gramMarket)) || 0;

      return {
        ...item,
        ing_quantity_sum: gramMarket,
        marketQuantity: marketQuantity,
        ingredientPrice: ingredientPrice,
        gram100Price: gram100Price,
      };
    });

    let tong = count_group
      ? (quantitative.costPerDays?.filter((e) => e.id_group == id_group)[0]
          ?.fee_other *
          count_group +
          ingredienthandleByIdGroup?.reduce(
            (sum, item) => sum + item.ingredientPrice,
            0
          )) /
        count_group
      : 0;
    return tong;
  };

  let tongdutru = ingredienthandleDuTtru?.reduce(
    (sum, item) => sum + item.ingredientPrice,
    0
  );

  let tongtienthu = quantitative.costPerDays?.reduce((sum, meal) => {
    return (
      sum +
      meal.meal_quantity * meal.fee_meal +
      meal.fee_other * meal.meal_quantity
    );
  }, 0);

  let chiphikhac = quantitative.costPerDays?.reduce((sum, meal) => {
    return sum + meal.fee_other * meal.meal_quantity;
  }, 0);

  let tongtiencho = ingredienthandle
    ?.filter((e) => e.ingredent_type == 1)
    .reduce((sum, item) => sum + item.ingredientPrice, 0);

  let tongtienkho = ingredienthandle
    ?.filter((e) => e.ingredent_type == 2)
    .reduce((sum, item) => sum + item.ingredientPrice, 0);

  let sodudauki = quantitative.costPerDays?.reduce((sum, meal) => {
    return sum + meal.start_balance;
  }, 0);

  let soducuoiki =
    sodudauki + tongtienthu - chiphikhac - tongtiencho - tongtienkho;

  return (
    <Table bordered responsive id={`market-bill-table-sum-bygroup`}>
      <thead>
        <tr>
          <td colSpan={5}></td>
          <td colSpan={2} className="text-end align-top">
            {`${quantitative.day_name}, ngày ${dateFormatter(
              new Date(quantitative.menu_date * 1000)
            )}`}
          </td>
        </tr>
        <tr>
          <th colSpan={7} className="text-center align-middle">
            HÓA ĐƠN ĐI CHỢ
          </th>
        </tr>
        <tr className="align-top">
          <td colSpan={3}>
            {quantitative?.costPerDays
              ?.filter((group) =>
                optionGroup
                  ?.map((option) => option.id)
                  ?.includes(group.id_group)
              )
              ?.map((group, indexGroup) => {
                return (
                  <span key={`group-key-${indexGroup}`}>
                    <span>Nhóm: {group.group_name}</span>; Số trẻ:{" "}
                    <span>{group.meal_quantity}</span>
                    <br></br>
                  </span>
                );
              })}
            <br />
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>

        <tr className="text-center align-middle">
          <th>STT</th>
          <th>NGUYÊN LIỆU</th>
          <th>NHÀ CUNG CẤP</th>
          <th>ĐƠN VỊ TÍNH</th>
          <th>SỐ LƯỢNG</th>

          <th>ĐƠN GIÁ</th>
          <th>THÀNH TIỀN</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>empty</th>
          <th>*CHỢ</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
        </tr>

        {ingredienthandle
          ?.filter((e) => e.ingredent_type == 1)
          ?.sort((a, b) => a?.ingredent_name?.localeCompare(b?.ingredent_name))
          ?.map((ingredient, index) => {
            return (
              <tr key={ingredient.id} className="align-middle text-end">
                <td className="text-center">{index + 1}</td>
                <td className="text-start">{ingredient.ingredent_name}</td>
                <td className="text-start">{ingredient.supplier_name}</td>
                <td className="text-start">{ingredient.unit_market_name}</td>
                <td>
                  <CurrencyFormat
                    value={parseFloat(ingredient.marketQuantity) || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                </td>
                <td className="text-start">
                  <CurrencyFormat
                    value={parseFloat(ingredient.ing_price_expect) || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                  />
                </td>

                <td>
                  <CurrencyFormat
                    value={parseFloat(ingredient.ingredientPrice) || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                  />
                </td>
              </tr>
            );
          })}
        <tr>
          <th className="text-center">Cộng</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th className="text-end">
            <CurrencyFormat
              value={tongtiencho || 0}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={0}
            />
          </th>
        </tr>
        <tr>
          <th>empty</th>
          <th>*XUẤT KHO</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
        </tr>
        {ingredienthandle
          ?.filter((e) => e.ingredent_type == 2)
          ?.sort((a, b) => a?.ingredent_name?.localeCompare(b?.ingredent_name))
          ?.map((ingredient, index) => {
            return (
              <tr key={ingredient.id} className="align-middle text-end">
                <td className="text-center">
                  {index +
                    1 +
                    ingredienthandle?.filter((e) => e.ingredent_type == 1)
                      ?.length}
                </td>
                <td className="text-start">{ingredient.ingredent_name}</td>
                <td className="text-start">{ingredient.supplier_name}</td>
                <td className="text-start">{ingredient.unit_market_name}</td>
                <td>
                  <CurrencyFormat
                    value={parseFloat(ingredient.marketQuantity) || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                  />
                </td>
                <td className="text-start">
                  <CurrencyFormat
                    value={parseFloat(ingredient.ing_price_expect) || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                  />
                </td>

                <td>
                  <CurrencyFormat
                    value={parseFloat(ingredient.ingredientPrice) || 0}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={0}
                  />
                </td>
              </tr>
            );
          })}
        <tr>
          <th className="text-center">Cộng</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th>empty</th>
          <th className="text-end">
            <CurrencyFormat
              value={tongtienkho}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={0}
              suffix=""
            />
          </th>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th> </th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        <tr>
          <th>Người lập </th>
          <th></th>
          <th></th>
          <th>Hiệu trưởng</th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
      </tfoot>
    </Table>
  );
};

export default MarketBillAllByGroupExport;
