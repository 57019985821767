import React, { Component } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import swal from "sweetalert";
import api from "../../../helper/axiosInstance";

class SupplierFormModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataInput: { ...this.props.data },
      isCheckGroup: [],
      err: {},
    };
  }

  handleInput = (event) => {
    let field = event.target.name;
    let value = event.target.value;
    let dataInput = { ...this.state.dataInput };
    dataInput[field] = value;
    this.setState({ dataInput: dataInput });
    delete this.state.err[field];
    this.setState({ err: { ...this.state.err } });
  };

  createSupplier = () => {
    let data = {
      supplier_name: this.state.dataInput?.supplier_name,
      supplier_phone: this.state.dataInput?.supplier_phone,
      supplier_author: this.state.dataInput?.supplier_author,
      supplier_address: this.state.dataInput?.supplier_address,
      supplier_shipper: this.state.dataInput?.supplier_shipper,
    };

    api({
      method: "post",
      url: "supplier-create",
      data: data,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((res) => {
        if (res.data.errCode == 0) {
          toast(`${res.data.message}`, { type: "success", autoClose: 1000 });

          return this.props.submit();
        } else {
          this.setState({ err: res.data.message });
        }
      })
      .catch((error) => {
        toast(`Đã phát sinh lỗi!`, { type: "error", autoClose: 1000 });
      });
  };

  updateSupplier = () => {
    let data = this.state.dataInput;
    swal({
      title: "Bạn muốn cập nhật nhà cung cấp?",
      icon: "warning",
      buttons: ["Đóng", "Cập nhật"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        api({
          method: "put",
          url: "supplier-update",
          data: data,
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((res) => {
            if (res.data.errCode == 0) {
              toast(`${res.data.message}`, {
                type: "success",
                autoClose: 1000,
              });

              return this.props.submit();
            } else {
              this.setState({ err: res.data.message });
            }
          })
          .catch((error) => {
            toast(`Đã phát sinh lỗi!`, { type: "error", autoClose: 1000 });
          });
      }
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.state.dataInput.id === "") {
      this.createSupplier();
    } else {
      this.updateSupplier();
    }
  };

  handleHide = () => {
    return this.props.onHide();
  };

  render() {
    const { onHide, ...other } = { ...this.props };
    return (
      <>
        <Modal
          show={other.show}
          onHide={onHide}
          size="md"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <i className="fa-solid fa-building-wheat" />
              &nbsp;{other.modal.title}
            </Modal.Title>
          </Modal.Header>

          <Form onSubmit={this.handleSubmit}>
            <Modal.Body>
              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={this.handleInput}
                  name="supplier_name"
                  value={this.state.dataInput.supplier_name}
                  placeholder="Tên nhà cung cấp"
                  required
                />
                <Form.Label>
                  Tên nhà cung cấp<i className="text-danger">*</i>{" "}
                </Form.Label>
                <span className="text-form-err">
                  {this.state.err?.supplier_name}
                </span>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={this.handleInput}
                  name="supplier_phone"
                  value={this.state.dataInput.supplier_phone}
                  placeholder="Số điện thoại"
                  required
                />
                <Form.Label>
                  Số điện thoại<i className="text-danger">*</i>
                </Form.Label>
                <span className="text-form-err">
                  {this.state.err?.supplier_phone}
                </span>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={this.handleInput}
                  name="supplier_address"
                  value={this.state.dataInput.supplier_address}
                  placeholder="Địa chỉ"
                />
                <Form.Label>Địa chỉ</Form.Label>
                <span className="text-form-err">
                  {this.state.err?.supplier_address}
                </span>
              </Form.Floating>

              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={this.handleInput}
                  name="supplier_author"
                  value={this.state.dataInput.supplier_author}
                  placeholder="Đại diện"
                  required
                />
                <Form.Label>
                  Đại diện<i className="text-danger">*</i>{" "}
                </Form.Label>
                <span className="text-form-err">
                  {this.state.err?.supplier_author}
                </span>
              </Form.Floating>
              <Form.Floating className="mb-3">
                <Form.Control
                  onChange={this.handleInput}
                  name="supplier_shipper"
                  value={this.state.dataInput.supplier_shipper}
                  placeholder="Người giao hàng"
                />
                <Form.Label>
                  Người giao hàng<i className="text-danger"></i>{" "}
                </Form.Label>
                <span className="text-form-err">
                  {this.state.err?.supplier_shipper}
                </span>
              </Form.Floating>
            </Modal.Body>

            <Modal.Footer>
              <Button
                size="sm"
                variant="secondary"
                id="btnCloseAddIngredent"
                onClick={this.handleHide}
              >
                <i className="fa-solid fa-times"></i> Đóng
              </Button>

              <Button size="sm" variant="success" type="submit">
                {other.modal.button}
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export default SupplierFormModal;
