import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Button,
  Card,
  Container,
  Modal,
  Table,
  Col,
  Form,
  Row,
} from "react-bootstrap";
import DataTables from "../../admin/datatable/DataTables";
import FormatData from "../../admin/FormatData";
import { toast } from "react-toastify";
import api from "../../../helper/axiosInstance";

export default function SupplierIndexHistory() {
  const [show, setShow] = useState(false);
  const [listIngredient, setListIngredient] = useState([]);
  const [listIngredientShow, setListIngredientShow] = useState([]);
  const [listHisIngredient, setListHistIngredient] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    document.title = "Lịch sử giá";

    getListIngredient();
  }, []);

  // Fetch API
  const getListIngredient = () => {
    api
      .get("/ingredent-show")
      .then((res) => {
        if (res.data.errCode == 0) {
          setListIngredient(res.data.ingredents);
          setListIngredientShow(res.data.ingredents);

          setIsLoading(false);

          handleSearchIngredient(res.data.ingredents, searchText);
        }
      })
      .catch((error) => {});
  };
  const getListHisIngredient = (idIngredient) => {
    api
      .get(`/ingredent-price-history?id=${idIngredient}`)
      .then((res) => {
        if (res.data.errCode == 0) {
          setListHistIngredient(res.data);
          setShow(true);
        } else {
          toast("Hệ thống xảy ra lỗi", { type: "error" });
        }
      })
      .catch((error) => {
        toast("Hệ thống xảy ra lỗi", { type: "error" });
      });
  };

  // Logic
  const handleSearchIngredient = (list, text) => {
    setSearchText(text);

    let filterList = [];

    filterList = list.filter((e) =>
      toSlug(e.ingredent_name).includes(toSlug(text))
    );

    if (text === "") {
      filterList = list;
    }

    setListIngredientShow(filterList);
  };

  const toSlug = (str) => {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường
    // xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    return str;
  };

  // Render
  const Loading = () => {
    return (
      <Card>
        <Card.Body className="d-flex justify-content-center align-items-center">
          <div
            className="spinner-border text-primary"
            role="status"
            style={{ width: "3rem", height: "3rem" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </Card.Body>
      </Card>
    );
  };
  const formatNumber = (num) => {
    if (num != null && num != "")
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  const getDate = (data) => {
    const date = new Date(data * 1000);
    date.setHours(0, 0, 0, 0);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear()
      // + " " +date.getHours() +':' +date.getMinutes()+':'+date.getHours()
    );
    // return ('0' + date.getDate()).slice(-2) + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear();
  };
  const DataTable = () => {
    const columns = [
      {
        name: "Tên nguyên liệu",
        selector: (row) => row.ingredent_name,
        sortable: true,
      },
      {
        name: "Giá (VNĐ)",
        selector: (row) => row.ingredent_price,
        sortable: true,
        right: true,
        cell: (row) => formatNumber(row.ingredent_price) || 0,
      },
      {
        name: "Cập nhật",
        selector: (row) => row.updated_at,
        sortable: true,
        right: true,
        cell: (row) => getDate(row.updated_at),
      },
      {
        name: "Phương thức",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = listIngredientShow.map((ingredient) => {
      return {
        ingredent_name: ingredient.ingredent_name,
        ingredent_price: parseFloat(ingredient.ing_price_expect),
        updated_at: parseFloat(ingredient.updated_at),
        setting: (
          <Button
            // variant="danger"
            size="sm"
            onClick={() => {
              getListHisIngredient(ingredient.id);
            }}
          >
            Lịch sử giá
          </Button>
        ),
      };
    });

    return <DataTables data={data} columns={columns} />;
  };

  const Hissupplierview = () => {
    return (
      <Modal
        size="lg"
        show={show}
        onHide={() => {
          setShow(false);
        }}
        keyboard={false}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Lịch sử giá - {listHisIngredient.ingredent?.ingredent_name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped>
            <thead>
              <tr>
                <th>Nhà cung cấp</th>
                <th className="text-center">Đơn vị tính</th>
                <th className="text-end">Giá (VND)</th>
                <th className="text-end">Ngày nhập</th>
              </tr>
            </thead>
            <tbody>
              {listHisIngredient?.priceHistories?.length > 0 ? (
                listHisIngredient?.priceHistories?.map((item, i) => {
                  let created_at = getDate(item.vou_date);
                  let ingredent_price = new FormatData().currency(
                    item.ing_price
                  );

                  return (
                    <tr key={`itemHistory-${i}`}>
                      <td>{item.supplier_name}</td>
                      <td className="text-center">{item.unit_market_name}</td>
                      <td className="text-end">{ingredent_price}</td>
                      <td className="text-end">{created_at}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <p className="text-center">Không có dữ liệu</p>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Kho hàng</Breadcrumb.Item>
        <Breadcrumb.Item active>Lịch sử giá</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <h5>
            <i className="fas fa-list me-1" /> Lịch sử giá nguyên liệu
          </h5>
        </Card.Header>
        <Card.Body>
          {isLoading ? (
            Loading()
          ) : listIngredient.length !== 0 ? (
            <>
              <Row>
                <Col lg={3} md={4} className="mb-3">
                  <Form.Control
                    style={{ height: 39 }}
                    type="text"
                    placeholder="Tìm nguyên liệu"
                    onChange={(e) => {
                      handleSearchIngredient(
                        listIngredient,
                        e.target.value.trim()
                      );
                    }}
                  />
                </Col>
              </Row>

              <DataTable />
            </>
          ) : (
            <p className="text-center">Không có dữ liệu...</p>
          )}
        </Card.Body>
      </Card>
      <Hissupplierview />
    </Container>
  );
}
