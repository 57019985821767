import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx-js-style";
import { toast } from "react-toastify";
import swal from "sweetalert";

import {
  Card,
  Container,
  Breadcrumb,
  Button,
  Row,
  Col,
  Form,
  Spinner,
  Table,
} from "react-bootstrap";

import { dateFormatter } from "../utils/helpers";
import moment from "moment";
import api from "../../../helper/axiosInstance";
import DatePickerCustom from "../../uiElements/DatePickerCustom";

const SampleBook = () => {
  const [school, setSchool] = useState({});

  const [listQuantitatives, setListQuantitatives] = useState([]);
  const [quantitative, setQuantitative] = useState({
    menu_date: new Date().getTime() / 1000,
  });
  const [formSetting, setFormSetting] = useState({
    // Sample book
    book_3: [],
    book_6: "",
    book_7: "",
    book_8: [],
    book_9: [],
    book_11: "",
    book_12: "",

    // Step 1
    step1_m1_3: new Date(),
    step1_m2_5: new Date(),
    step1_m2_11: "",

    // Step 2
    step2_6: [],
    step2_7: [],
    step2_8: [],
    step2_9: "",
    step2_10: "",

    // Step 3
    step3_2: [],
    step3_5: [],
    step3_6: [],
    step3_7: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [listDays, setListDays] = useState([]);
  const [selectedDay, setSelectedDay] = useState({});
  const [times, setTimes] = useState([]);

  useEffect(() => {
    document.title = "Sổ lưu mẫu";

    let initialWeek = datesOfWeek(new Date());

    let todayIs = new Date().getDay();
    if (todayIs === 0) {
      todayIs = 7;
    }

    getListQuantitatives(initialWeek[0], initialWeek[6], todayIs);
    getDetailschool();
    getSettingFormData();
    getTimes();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  //#region API
  const getDetailschool = async () => {
    await api
      .get(`/detailSchool`)
      .then((res) => {
        if (res.data.status === true) {
          setSchool(res.data.data[0]);
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  const getListQuantitatives = async (startDate, endDate, dayId) => {
    setIsLoading(true);
    setListQuantitatives([]);
    setQuantitative({
      menu_date: startDate.getTime() / 1000,
    });

    await api
      .get(`menu-show`, {
        params: {
          start_at: startDate.getTime() / 1000,
          term_at: endDate.getTime() / 1000,
        },
      })
      .then((res) => {
        setIsLoading(false);

        if (res.data.errCode === 0) {
          setListDays(
            res.data.days.map((dayItem) => {
              return {
                id_day: dayItem.id_day,
                day_name: dayItem.day_name,
                date: new Date(dayItem.menu_date * 1000),
              };
            })
          );

          setListQuantitatives(res.data.days);
          setQuantitative(
            res.data.days.find(
              (listQuantitativesItem) => listQuantitativesItem.id_day === dayId
            )
              ? res.data.days.find(
                  (listQuantitativesItem) =>
                    listQuantitativesItem.id_day === dayId
                )
              : res.data.days[0]
          );
          setSelectedDay(
            res.data.days.find(
              (listQuantitativesItem) => listQuantitativesItem.id_day === dayId
            )
              ? res.data.days.find(
                  (listQuantitativesItem) =>
                    listQuantitativesItem.id_day === dayId
                )?.id_day
              : res.data.days[0].id_day
          );
        } else {
          toast(res.data.message, {
            type: "error",
            autoClose: 1000,
          });
        }
      })
      .catch((error) => {});
  };

  const getSettingFormData = async () => {
    await api
      .get("/setting-form-show")
      .then((res) => {
        if (res.data.errCode === 0) {
          setFormSetting(res.data.settingForm);
        }
      })
      .catch((error) => {});
  };

  const getTimes = async () => {
    await api
      .get("/getTimeOfDays")
      .then((res) => {
        if (res.data.errCode === 0) {
          setTimes(res.data.times);
        }
      })
      .catch((error) => {});
  };
  // #endregion

  //#region Logic
  const exportExcel = () => {
    const table = document.getElementById("sample-book");

    const wb = XLSX.utils.book_new();

    var ws = XLSX.utils.table_to_sheet(table, { raw: true });

    // Style cell
    const colAlpha_title1 = ["A", "B", "C", "D", "E", "F", "G", "H"];
    const colAlpha_title2 = ["I", "J", "K", "L"];

    const fontStyle = {
      name: "Times New Roman",
    };
    const borderStyle = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };

    colAlpha_title1.map((alpha) => {
      ws[`${alpha}1`].s = {
        font: fontStyle,
        alignment: {
          vertical: "top",
          horizontal: "left",
          wrapText: true,
        },
      };

      return alpha;
    });

    colAlpha_title2.map((alpha) => {
      ws[`${alpha}1`].s = {
        font: {
          ...fontStyle,
          bold: true,
        },
        alignment: {
          vertical: "top",
          horizontal: "right",
          wrapText: true,
        },
      };
      return alpha;
    });

    const colAlpha = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
    ];

    colAlpha.map((alpha) => {
      ws[`${alpha}2`].s = {
        font: fontStyle,
        alignment: {
          vertical: "center",
          horizontal: "center",
          wrapText: true,
        },
        border: borderStyle,
      };

      ws[`${alpha}3`].s = {
        font: fontStyle,
        alignment: {
          vertical: "center",
          horizontal: "center",
        },
        border: borderStyle,
      };

      return alpha;
    });

    let ingredientLen = 0;

    quantitative.times?.map((timeItem) => {
      ingredientLen += timeItem.foods.length;
      return timeItem;
    });

    let rowWidth = [{ hpt: 40 }, { hpt: 55 }, {}];

    const intLen = 4;

    for (let i = 0; i < ingredientLen; i++) {
      rowWidth.push({ hpt: 35 });

      colAlpha.map((alpha) => {
        if (ws[`${alpha}${intLen + i}`].v === "empty") {
          ws[`${alpha}${intLen + i}`].v = " ";
        }

        if (
          alpha === "A" ||
          alpha === "C" ||
          alpha === "D" ||
          alpha === "G" ||
          alpha === "H" ||
          alpha === "I" ||
          alpha === "K" ||
          alpha === "L"
        ) {
          ws[`${alpha}${intLen + i}`].s = {
            font: fontStyle,
            border: borderStyle,
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "center",
            },
          };
        } else if (alpha === "B" || alpha === "F") {
          ws[`${alpha}${intLen + i}`].s = {
            font: fontStyle,
            border: borderStyle,
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "left",
            },
          };
        } else {
          ws[`${alpha}${intLen + i}`].s = {
            font: fontStyle,
            border: borderStyle,
            alignment: {
              wrapText: true,
              vertical: "center",
              horizontal: "center",
            },
          };
        }

        return alpha;
      });

      const str = ws[`C${intLen + i}`.toString()].v;
      ws[`C${intLen + i}`.toString()].v = str.replaceAll("Selected time:", "");

      const str1 = ws[`H${intLen + i}`.toString()].v;
      ws[`H${intLen + i}`.toString()].v = str1.split("#")[0];
    }

    var wscols = [
      { wch: 3.5 },
      { wch: 15 },
      { wch: 6 },
      { wch: 6 },
      { wch: 8 },
      { wch: 12 },
      { wch: 6 },
      { wch: 11 },
      { wch: 11 },
      { wch: 12 },
      { wch: 12 },
      { wch: 19 },
    ];

    ws["!cols"] = wscols;
    ws["!rows"] = rowWidth;

    const merge = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 7 } },
      { s: { r: 0, c: 8 }, e: { r: 0, c: 11 } },
    ];
    ws["!merges"] = merge;

    XLSX.utils.book_append_sheet(wb, ws, "Sổ lưu mẫu ngày");

    XLSX.writeFile(
      wb,
      `Sổ lưu mẫu (${dateFormatter(
        new Date(quantitative.menu_date * 1000)
      )}).xlsx`
    );
  };

  const handleXportSampleBook = () => {
    swal({
      title: `Bạn muốn xuất file sổ lưu mẫu`,
      text: `Ngày ${dateFormatter(new Date(quantitative.menu_date * 1000))}`,
      icon: "info",
      buttons: ["Đóng", "Xuất"],
      successMode: true,
    }).then((ok) => {
      if (ok) {
        exportExcel();
      }
    });
  };

  const datesOfWeek = (current) => {
    var week = [];
    // Starting Monday not Sunday
    current.setDate(current.getDate() - current.getDay() + 1);
    current.setHours(0, 0, 0, 0);
    for (var i = 0; i < 7; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
      current.setHours(0, 0, 0, 0);
    }
    return week;
  };
  // #endregion

  // Render
  const RenderOption = () => {
    return (
      <section className="mb-3">
        <Card.Title>Chọn ngày điền sổ lưu mẫu</Card.Title>

        <Row className="my-3">
          {listDays.map((dayItem) => {
            return (
              <Col key={dayItem.id_day} md="auto" xs={6} className="d-flex">
                <Form.Check
                  type="radio"
                  value={dayItem.id_day}
                  checked={selectedDay === dayItem.id_day}
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedDay(Number(e.target.value));
                      setQuantitative(
                        listQuantitatives.find(
                          (quantitativeItem) =>
                            quantitativeItem.id_day === Number(e.target.value)
                        )
                      );
                    }
                  }}
                  name="day"
                  className="me-2"
                />
                {dayItem.day_name}
              </Col>
            );
          })}
        </Row>
      </section>
    );
  };

  const RenderSampleBook = () => {
    const costPerDays = quantitative?.costPerDays || [];
    let bookIndex = 0;
    let bookIndexreport = 0;

    return (
      <div style={{ overflowX: "scroll" }}>
        <Table bordered style={{ minWidth: 999 }}>
          <thead>
            <tr>
              <td className="align-top" colSpan={8}>
                Tên cơ sở: {school?.school_name}
                <br />
                Địa điểm kiểm tra: {formSetting.book_check_address} (Ngày{" "}
                {dateFormatter(new Date(quantitative?.menu_date * 1000))})
              </td>
              <th className="align-top text-end" colSpan={4}>
                Mẫu sổ theo dõi lưu và hủy thức ăn lưu
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center align-middle">
              <td>TT</td>
              <td>Tên mẫu thức ăn</td>
              <td>Bữa ăn (giờ ăn...)</td>
              <td>Số lượng suất ăn</td>
              <td>Khối lượng/thể tích mẫu (gam/ml)</td>
              <td>Dụng cụ chứa mẫu thức ăn lưu</td>
              <td>Nhiệt độ bảo quản mẫu (℃)</td>
              <td>Thời gian lấy mẫu (giờ, ngày, tháng, năm)</td>
              <td>Thời gian hủy mẫu (giờ, ngày, tháng, năm)</td>
              <td>Ghi chú (chất lượng mẫu thức ăn lưu...)</td>
              <td>Người lưu mẫu (ký và ghi rõ họ tên)</td>
              <td>Người hủy mẫu (ký và ghi rõ họ tên)</td>
            </tr>
            <tr className="text-center align-middle">
              <td>(1)</td>
              <td>(2)</td>
              <td>(3)</td>
              <td>(4)</td>
              <td>(5)</td>
              <td>(6)</td>
              <td>(7)</td>
              <td>(8)</td>
              <td>(9)</td>
              <td>(10)</td>
              <td>(11)</td>
              <td>(12)</td>
            </tr>

            {quantitative?.times?.map((timeItem) => {
              return (
                <React.Fragment key={timeItem.id_time}>
                  {timeItem.foods.map((foodItem) => {
                    let mealQuantity = 0;

                    foodItem.groups.map((groupItem) => {
                      mealQuantity += groupItem.more_meal
                        ? groupItem.more_meal
                        : Number(
                            costPerDays.find(
                              (costPerDay) =>
                                costPerDay.id_group === groupItem.id
                            )?.meal_quantity ?? 0
                          );
                      return groupItem;
                    });

                    bookIndex++;

                    return (
                      <tr
                        key={foodItem.id}
                        className="text-center align-middle"
                      >
                        <td>{bookIndex}</td>
                        <td className="text-start">{foodItem.food_name}</td>
                        <td>
                          {
                            times.find(
                              (timeObj) => timeObj.id === timeItem.id_time
                            )?.time_name_now
                          }{" "}
                          {moment(
                            Number(
                              formSetting.book_3?.find(
                                (item, timeObjIndex) =>
                                  parseInt(item.time) === timeItem.id_time
                              )?.val
                            )
                          ).format("HH:mm")}
                        </td>
                        <td>{mealQuantity}</td>
                        <td>
                          {foodItem.state_value} {foodItem.state_unit}
                        </td>
                        <td>{formSetting.book_6}</td>
                        <td>{formSetting.book_7} ℃</td>
                        <td>
                          {
                            times.find(
                              (timeObj) => timeObj.id === timeItem.id_time
                            )?.time_name_now
                          }{" "}
                          {moment(
                            Number(
                              formSetting.book_8?.find(
                                (item, timeObjIndex) =>
                                  parseInt(item.time) === timeItem.id_time
                              )?.val
                            )
                          ).format("HH:mm")}{" "}
                          (
                          {dateFormatter(
                            new Date(quantitative?.menu_date * 1000)
                          )}
                          )
                        </td>
                        <td>
                          {
                            times.find(
                              (timeObj) => timeObj.id === timeItem.id_time
                            )?.time_name_now
                          }{" "}
                          {moment(
                            Number(
                              formSetting.book_9?.find(
                                (item, timeObjIndex) =>
                                  parseInt(item.time) === timeItem.id_time
                              )?.val
                            )
                          ).format("HH:mm")}{" "}
                          (
                          {dateFormatter(
                            new Date(quantitative?.menu_date * 1000 + 86400000)
                          )}
                          )
                        </td>
                        <td></td>
                        <td>{formSetting.book_11}</td>
                        <td>{formSetting.book_12}</td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>

        <Table className="d-none" bordered id="sample-book">
          <thead>
            <tr>
              <td className="align-top">
                Tên cơ sở: {school?.school_name}
                <br />
                Địa điểm kiểm tra: {formSetting.book_check_address} (Ngày{" "}
                {dateFormatter(new Date(quantitative?.menu_date * 1000))})
              </td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>
              <td>empty</td>

              <th className="align-top text-end">
                Mẫu sổ theo dõi lưu và hủy thức ăn lưu
              </th>
              <th>empty</th>
              <th>empty</th>
              <th>empty</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center align-middle">
              <td>TT</td>
              <td>Tên mẫu thức ăn</td>
              <td>Bữa ăn (giờ ăn...)</td>
              <td>Số lượng suất ăn</td>
              <td>Khối lượng/thể tích mẫu (gam/ml)</td>
              <td>Dụng cụ chứa mẫu thức ăn lưu</td>
              <td>Nhiệt độ bảo quản mẫu (℃)</td>
              <td>Thời gian lấy mẫu (giờ, ngày, tháng, năm)</td>
              <td>Thời gian hủy mẫu (giờ, ngày, tháng, năm)</td>
              <td>Ghi chú (chất lượng mẫu thức ăn lưu...)</td>
              <td>Người lưu mẫu (ký và ghi rõ họ tên)</td>
              <td>Người hủy mẫu (ký và ghi rõ họ tên)</td>
            </tr>
            <tr className="text-center align-middle">
              <td>(1)</td>
              <td>(2)</td>
              <td>(3)</td>
              <td>(4)</td>
              <td>(5)</td>
              <td>(6)</td>
              <td>(7)</td>
              <td>(8)</td>
              <td>(9)</td>
              <td>(10)</td>
              <td>(11)</td>
              <td>(12)</td>
            </tr>

            {quantitative?.times?.map((timeItem) => {
              return (
                <React.Fragment key={timeItem.id_time}>
                  {timeItem.foods.map((foodItem) => {
                    let mealQuantity = 0;

                    foodItem?.groups.map((groupItem) => {
                      mealQuantity += groupItem.more_meal
                        ? groupItem.more_meal
                        : Number(
                            costPerDays.find(
                              (costPerDay) =>
                                costPerDay.id_group === groupItem.id
                            )?.meal_quantity
                          );
                      return groupItem;
                    });

                    bookIndexreport++;

                    return (
                      <tr
                        key={foodItem.id}
                        className="text-center align-middle"
                      >
                        <td>{bookIndexreport}</td>
                        <td className="text-start">{foodItem.food_name}</td>
                        <td>
                          {
                            times.find(
                              (timeObj) => timeObj.id === timeItem.id_time
                            )?.time_name_now
                          }{" "}
                          {moment(
                            Number(
                              formSetting.book_3?.find(
                                (item, timeObjIndex) =>
                                  parseInt(item.time) === timeItem.id_time
                              )?.val
                            )
                          ).format("HH:mm")}
                        </td>
                        <td>{mealQuantity}</td>
                        <td>
                          {foodItem.state_value} {foodItem.state_unit}
                        </td>
                        <td>{formSetting.book_6}</td>
                        <td>{formSetting.book_7} ℃</td>
                        <td>
                          {
                            times.find(
                              (timeObj) => timeObj.id === timeItem.id_time
                            )?.time_name_now
                          }{" "}
                          {moment(
                            Number(
                              formSetting.book_8?.find(
                                (item, timeObjIndex) =>
                                  parseInt(item.time) === timeItem.id_time
                              )?.val
                            )
                          ).format("HH:mm")}{" "}
                          (
                          {dateFormatter(
                            new Date(quantitative.menu_date * 1000)
                          )}
                          )
                        </td>
                        <td>
                          {
                            times.find(
                              (timeObj) => timeObj.id === timeItem.id_time
                            )?.time_name_now
                          }{" "}
                          {moment(
                            Number(
                              formSetting.book_9?.find(
                                (item, timeObjIndex) =>
                                  parseInt(item.time) === timeItem.id_time
                              )?.val
                            )
                          ).format("HH:mm")}{" "}
                          (
                          {dateFormatter(
                            new Date(quantitative?.menu_date * 1000 + 86400000)
                          )}
                          )
                        </td>
                        <td>empty</td>
                        <td>{formSetting.book_11}</td>
                        <td>{formSetting.book_12}</td>
                      </tr>
                    );
                  })}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <Container fluid>
      <Breadcrumb>
        <Breadcrumb.Item active>Biểu mẫu</Breadcrumb.Item>
        <Breadcrumb.Item active>Sổ lưu mẫu ngày</Breadcrumb.Item>
      </Breadcrumb>
      <Card>
        <Card.Header>
          <div className="d-block d-sm-flex justify-content-between">
            <div className="d-block d-sm-flex mb-3 mb-sm-0">
              <Card.Title className="me-3 pt-1">
                <i className="fas fa-list me-1"></i> Sổ lưu mẫu{" "}
                {dateFormatter(new Date(quantitative?.menu_date * 1000))}
              </Card.Title>

              <div className="d-flex align-items-center">
                {/* <input
                  type="week"
                  name="week"
                  style={{
                    border: "none",
                    width: 39,
                    backgroundColor: "rgb(16,108,252)",
                    borderRadius: 5,
                  }}
                  onChange={(e) => {
                    const today = e.target.value
                      ? new Date(e.target.valueAsNumber)
                      : new Date();
                    const week = datesOfWeek(today);

                    getListQuantitatives(week[0], week[6], selectedDay);
                  }}
                /> */}
                <div className="d-flex">
                  <DatePickerCustom
                    startDate={
                      datesOfWeek(new Date(quantitative.menu_date * 1000))[0]
                    }
                    endDate={
                      datesOfWeek(new Date(quantitative.menu_date * 1000))[6]
                    }
                    onChange={(e) => {
                      const today = e ? new Date(e) : new Date();
                      const week = datesOfWeek(today);

                      getListQuantitatives(week[0], week[6], selectedDay);
                    }}
                    showWeekNumbers
                    showWeekPicker
                  />
                </div>
              </div>
            </div>

            <Button
              variant="success"
              size="sm"
              onClick={() => handleXportSampleBook()}
            >
              Xuất file <i className="fa-solid fa-file-export"></i>
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          {RenderOption()}

          {isLoading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner
                animation="border"
                role="status"
                variant="primary"
                style={{ width: "3rem", height: "3rem" }}
              >
                <span className="visually-hidden">Đang tải...</span>
              </Spinner>
            </div>
          ) : (
            RenderSampleBook()
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default SampleBook;
