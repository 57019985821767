import React, { Component } from "react";
import { toast } from "react-toastify";
import swal from "sweetalert";
import {
  Button,
  Row,
  Col,
  Card,
  Container,
  Breadcrumb,
  Alert,
  Tabs,
  Tab,
  Form,
} from "react-bootstrap";
import Check from "../other/Check";
import FormatData from "../FormatData";
import IngredentModal from "./IngredentModal";
import DataTables from "../datatable/DataTables";
import api from "../../../helper/axiosInstance";

class IngredentIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ingredents: [],
      ingredents_search: [],
      ingredentTextSearch: "",
      deps: [],
      addModalShow: false,
      alertShow: false,
      ingredent: [],
      suppliers: [],
      units: [],
      selectedRows: [],
      tabkey: "1",
      typeModal: "",
    };
  }

  componentDidMount() {
    document.title = "Nguyên liệu";
    this.getIngredentsData(this.state.tabkey);
  }

  changeTab = (key) => {
    this.setState({ tabkey: key });
    this.getIngredentsData(key);
    this.setState({ ingredentTextSearch: "" });
  };

  getIngredentsData = (key) => {
    api
      .get(`/ingredent-show?ingredent_type=${key}`)
      .then((res) => {
        if (res.data.errCode == 0) {
          this.setState({
            ingredents: res.data.ingredents.sort(function (a, b) {
              return b.updated_at - a.updated_at;
            }),
          });
          this.handleIngredentSearch(
            res.data.ingredents.sort(function (a, b) {
              return b.updated_at - a.updated_at;
            }),
            this.state.ingredentTextSearch
          );
        }
      })
      .catch(() => {});
  };

  handleDelete = (event) => {
    let id_ingredent = event.currentTarget.dataset.ingredent_id;

    swal({
      title: "Bạn muốn xóa nguyên liệu?",
      icon: "error",
      buttons: ["Đóng", "Xóa"],
      dangerMode: true,
    }).then((ok) => {
      if (ok) {
        api.delete(`/ingredent-destroy?id=${id_ingredent}`).then((res) => {
          if (res.data.errCode == 0) {
            toast(res.data.message, { type: "success", autoClose: 1000 });
            this.getIngredentsData(this.state.tabkey);
          } else {
            toast(res.data.message || "Hệ thống xảy lỗi", {
              type: "error",
              autoClose: 1000,
            });
          }
        });
      }
    });
  };

  delete(id) {
    api.post(`/deleteIngredent?id_ingredent=${id}`).then((res) => {
      return res.data.status;
    });
  }

  handleDeleteList = () => {
    let selectedRows = this.state.selectedRows;

    if (selectedRows.length <= 0) {
      toast("Vui lòng chọn mục bạn muốn xóa.", { type: "warning" });
    } else {
      swal({
        title: "Bạn có chắc muốn xóa?",
        text: `${selectedRows.map((r) => r.title)}.`,
        icon: "error",
        buttons: ["Đóng", "Xóa"],
        dangerMode: true,
      }).then((ok) => {
        if (ok) {
          selectedRows.map((r) =>
            api.post(`/deleteIngredent?id_ingredent=${r.id}`).then((res) => {
              if (res.data.status === true) {
                toast(res.data.data.mess, { type: "success", autoClose: 1000 });
              }
            })
          );
          return this.getIngredentsData(this.state.tabkey);
        }
      });
    }
  };

  getDate = (data) => {
    const date = new Date(data * 1000);
    return (
      ("0" + date.getDate()).slice(-2) +
      "/" +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      "/" +
      date.getFullYear() +
      " " +
      ("0" + date.getHours()).slice(-2) +
      ":" +
      ("0" + date.getMinutes()).slice(-2) +
      ":" +
      ("0" + date.getSeconds()).slice(-2)
    );
  };

  dataTable(key) {
    const columns = [
      {
        name: "Nguyên liệu",
        selector: (row) => row.title,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.is_bank ? (
              <i
                className="fa-solid fa-check-circle"
                style={{ color: "green" }}
              />
            ) : (
              ""
            )}{" "}
            {row.title}
          </div>
        ),
      },
      {
        name: "Nhà cung cấp",
        selector: (row) => row.supplierName,
        sortable: true,
        cell: (row) => (
          <div style={{ width: "100%" }} className="text-wrap">
            {row.supplierName}
          </div>
        ),
        style: { div: { width: "200px !important" } },
      },
      {
        name: "Đơn vị",
        selector: (row) => row.unitName,
        sortable: true,
        center: true,
      },
      {
        name: "Giá (VND)",
        selector: (row) => parseFloat(row.ingredentPrice),
        sortable: true,
        right: true,
        cell: (row) => new FormatData().currency(row.ingredentPrice),
      },
      {
        name: "Cập nhật",
        selector: (row) => row.updatedAt,
        sortable: true,
        center: true,
        cell: (row) => (
          <div className="text-wrap">{this.getDate(row.updatedAt)}</div>
        ),
      },
      {
        name: "",
        selector: (row) => row.setting,
        center: true,
      },
    ];

    const data = [];

    const selectedRows = (items) => {
      this.setState({ selectedRows: items, alertShow: true });
    };

    if (this.state.ingredents_search.length > 0) {
      this.state.ingredents_search.map((item, i) => {
        data.push({
          id: item.id,
          title: item.ingredent_name,
          is_bank: item.is_bank,
          ingredentTypeName: item.ingredentType_name,
          supplierName: item.supplier_name,
          unitName: item.unit_market_name,
          ingredentPrice:
            item.ing_price_expect === null ? 0 : item.ing_price_expect,
          updatedAt: item.updated_at,
          setting: (
            <>
              {new Check().permission(["40", "42"]) ? (
                <Button
                  variant="warning me-2"
                  type="button"
                  size="sm"
                  onClick={() => this.addModalShow(item)}
                  title="Cập nhật nguyên liệu"
                >
                  <i className="fa-solid fa-pencil"></i>
                </Button>
              ) : null}

              {new Check().permission(["43"]) ? (
                <Button
                  variant="danger"
                  type="button"
                  size="sm"
                  onClick={this.handleDelete}
                  data-ingredent_id={item.id}
                  title="Xóa nguyên liệu"
                >
                  <i className="fas fa-trash"></i>
                </Button>
              ) : null}
            </>
          ),
        });

        return item;
      });
    }

    return (
      <DataTables data={data} columns={columns} selectedRows={selectedRows} />
    );
  }

  addModalShow = (item) => {
    api.get(`/ingredent-show?id=${item.id}`).then((res) => {
      if (res.data.errCode == 0) {
        this.setState({
          ingredent: res.data.ingredent,
          addModalShow: true,
          typeModal: "CN",
        });
        this.getIngredentsData(this.state.tabkey);
      } else {
        toast(res.data.data.mess, { type: "error", autoClose: 1000 });
      }
    });
  };

  renderSelectedRows() {
    let num = this.state.selectedRows.length;
    if (num > 0 && this.state.alertShow === true) {
      return (
        <Alert
          variant="info mb-0 py-2"
          onClose={() => this.setState({ alertShow: false })}
          dismissible
        >
          {num} mục đã chọn.
        </Alert>
      );
    }
  }

  toSlug(str) {
    str = str.toLowerCase(); // Chuyển hết sang chữ thường
    // xóa dấu
    str = str
      .normalize("NFD") // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ""); // xóa các ký tự dấu sau khi tách tổ hợp
    str = str.replace(/[đĐ]/g, "d"); // Thay ký tự đĐ
    str = str.replace(/([^0-9a-z-\s])/g, ""); // Xóa ký tự đặc biệt
    return str;
  }

  handleIngredentSearch(root, text) {
    let root_ = root;
    this.setState({
      ingredentTextSearch: text,
    });
    if (text !== "") {
      root_ = root.filter((e) =>
        this.toSlug(e.ingredent_name).includes(this.toSlug(text.trim()))
      );
    }
    this.setState({
      ingredents_search: root_,
    });
  }

  boxSearch() {
    return (
      <Col className="mb-2 " md={3}>
        <Form.Control
          style={{ height: 39 }}
          type="text"
          placeholder="Tìm nguyên liệu"
          value={this.state.ingredentTextSearch}
          onChange={(e) => {
            this.handleIngredentSearch(this.state.ingredents, e.target.value);
          }}
        />
      </Col>
    );
  }

  renderIngredentList() {
    const addModalClose = () => {
      this.setState({ addModalShow: false, ingredent: [] });
      this.getIngredentsData(this.state.tabkey);
    };

    return (
      <>
        <Card.Header>
          {new Check().permission(["41"]) ? (
            <Button
              type="button"
              variant="success"
              size="sm"
              style={{ float: "right" }}
              onClick={() =>
                this.setState({ addModalShow: true, typeModal: "TM" })
              }
              title="Thêm nguyên liệu"
            >
              <i className="fa-solid fa-plus"></i> Thêm
            </Button>
          ) : null}
          <Card.Title>
            <i className="fas fa-list me-1"></i> Danh sách nguyên liệu
          </Card.Title>
        </Card.Header>

        <Card.Body>
          <Row>{this.boxSearch()}</Row>

          <Row>
            <Col>
              <Tabs
                defaultActiveKey={this.state.tabkey}
                onSelect={(k) => {
                  this.changeTab(k);
                }}
                className="mb-1"
              >
                <Tab key={1} title="Hàng chợ" eventKey={1}>
                  {this.dataTable(1)}
                </Tab>
                <Tab key={2} title="Hàng kho" eventKey={2}>
                  {this.dataTable(2)}
                </Tab>
                <Tab key={3} title="Vật tư" eventKey={3}>
                  {this.dataTable(3)}
                </Tab>
              </Tabs>
            </Col>
          </Row>
        </Card.Body>

        {this.state.addModalShow ? (
          <IngredentModal
            show={this.state.addModalShow}
            onHide={addModalClose}
            keyTab={this.state.tabkey}
            typeModal={this.state.typeModal}
            obj={{ ingredent: this.state.ingredent }}
          />
        ) : (
          ""
        )}
      </>
    );
  }

  render() {
    return (
      <>
        <Container fluid className="px-3">
          <Breadcrumb className="mb-2 mt-2">
            <Breadcrumb.Item active>Kho hàng</Breadcrumb.Item>
            <Breadcrumb.Item active>Nguyên liệu</Breadcrumb.Item>
          </Breadcrumb>
          <Row>
            <Col md={12}>
              <Card className="mb-4">{this.renderIngredentList()}</Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default IngredentIndex;
